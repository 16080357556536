import React, { memo, useMemo } from "react";
import { LayoutDefault } from "@app/layouts";
import { Contracts, TabRoute, TabRouter } from "@app/common";
import { Route, Routes } from "react-router-dom";
import ActTasks from "../../common/ActTasks";
import { DocumentType } from "@app/models";
import { useUser } from "@app/providers";
import Accession from "../../common/Accession";

function PageTasks() {
  const { userCounts } = useUser();

  const routes: TabRoute[] = useMemo(
    () => [
      {
        label: "Договоры",
        path: "/tasks/contracts",
        element: null,
        badge: userCounts?.contractCount,
      },
      {
        label: "Табели",
        path: "/tasks/acts",
        element: null,
        badge: userCounts?.actCount,
      },
      {
        label: "Служебные записки",
        path: "/tasks/notes",
        element: null,
        badge: userCounts?.memoCount,
      },
      {
        label: "Договор присоединения",
        path: "/tasks/accession",
        element: null,
        badge: userCounts?.memoCount,
      },
    ],
    [userCounts]
  );

  return (
    <LayoutDefault title="Мои задачи">
      <TabRouter routes={routes}>
        <Routes>
          <Route
            path="/contracts"
            element={<Contracts documentType={DocumentType.Contract} />}
          />
          <Route
            path="/acts"
            element={<ActTasks documentType={DocumentType.Act} />}
          />
          <Route path="/notes" element={null} />
          <Route path="/accession" element={<Accession />} />
        </Routes>
      </TabRouter>
    </LayoutDefault>
  );
}

export default memo(PageTasks);
