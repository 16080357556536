import React, { memo, PropsWithChildren, useCallback, useMemo } from "react";
import { CalendarDay, ICalendarDay } from "./components";
import "./styles.scss";
import { capitalize } from "@app/helpers";
import { format, isSameDay } from "date-fns";
import { ru } from "date-fns/locale";
import { IconChevronLeft16, IconChevronRight16 } from "@app/icons";
import { useCalendarCustom } from "../useCalendarCustom";

export type ICalendarWeek = ICalendarDay[];

interface Props extends PropsWithChildren {
  value: Date | null;
  onClick: (day: ICalendarDay) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  currentMonth: Date;
  setCurrentMonth: (date: Date) => void;
}

function CalendarCustom(props: Props) {
  const {
    minDate,
    maxDate,
    onClick,
    value,
    currentMonth,
    setCurrentMonth,
    children,
  } = props;
  const { data, daysOfWeek, nextMonth, prevMonth } = useCalendarCustom({
    date: value,
    currentMonth,
    setCurrentMonth,
  });

  const onClickDay = useCallback(
    (date: ICalendarDay) => {
      setCurrentMonth(date.day);
      onClick(date);
    },
    [onClick, setCurrentMonth]
  );

  const weekNames = useMemo<string[]>(() => {
    return ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
  }, []);

  const calendarData = useMemo<ICalendarWeek[]>(() => {
    return data.map((week) =>
      week.map((day) => {
        return {
          day: day.day,
          selected: !value ? false : isSameDay(day.day, value),
          disabled: false,
        };
      })
    );
  }, [data, maxDate, minDate, value]);

  return (
    <div className="dispatch-calendar">
      <div className="dispatch-calendar__header">
        <p className="dispatch-calendar__title">
          {capitalize(format(currentMonth, "LLLL yyyy", { locale: ru }))}
        </p>
        <div className="dispatch-calendar__controls">
          <button onClick={prevMonth} className="dispatch-calendar__control">
            <IconChevronLeft16 />
          </button>
          <button onClick={nextMonth} className="dispatch-calendar__control">
            <IconChevronRight16 />
          </button>
        </div>
      </div>
      <div className="dispatch-calendar__content">
        <div className="dispatch-calendar__week">
          {weekNames.map((day, dayIndex) => (
            <CalendarDay day={day} key={dayIndex.toString()} />
          ))}
        </div>
        {calendarData.map((week, weekIndex) => (
          <div className="dispatch-calendar__week" key={weekIndex.toString()}>
            {week.map((day, dayIndex) => (
              <CalendarDay
                {...day}
                onClick={onClickDay}
                key={dayIndex.toString()}
              />
            ))}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
}

export default memo(CalendarCustom);
