import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DateTimePicker, Grid, Select, SelectOption } from "@app/components";
import {
  Act,
  Company,
  ContractActParams,
  Currency,
  NdsShort,
  Project,
  SmartContract,
} from "@app/models";
import {
  getCompaniesFiltered,
  getContractsForAct,
  getCurrencies,
  getMyCompanies,
  getMyProjects,
  getProjects,
  PageableResponse,
} from "@app/api";
import { useUser } from "@app/providers";
import { isDispatcher } from "@app/helpers";
import styled from "styled-components";
import { FilterParams } from "../types";

type FilterErrors = {
  [key in keyof FilterParams]?: string;
};

interface Props {
  actData: Act | null;
  filterParams?: FilterParams | null;
  onFilterChange: (
    value: FilterParams[keyof FilterParams],
    key: keyof FilterParams
  ) => void;
  filterErrors: FilterErrors;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
`;

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: "",
};

function Step1(props: Props) {
  const { onFilterChange, filterParams, filterErrors } = props;
  const { user } = useUser();
  // const { showNotification } = useNotification();
  const [selectedCompanyNDS, setSelectedCompanyNDS] =
    useState<SelectOption<any>>();
  const [errors, setErrors] = useState<FilterErrors>({});
  const [companies, setCompanies] = useState<SelectOption<any>[]>([]);
  const [myCompanies, setMyCompanies] = useState<SelectOption<any>[]>([]);
  const [myPartners, setPartners] = useState<SelectOption<any>[]>([]);
  const [currencies, setCurrencies] = useState<SelectOption<Currency>[]>([]);

  useEffect(() => {
    setErrors(filterErrors);
  }, [filterErrors]);

  const vehicleTypes: SelectOption<any>[] = useMemo(() => {
    return [
      {
        label: "Колесная техника",
        value: "false",
        item: null,
      },
      {
        label: "ГПМ",
        value: "true",
        item: null,
      },
    ];
  }, []);

  // очищать Договор при изменении
  const onChange = (
    value: SelectOption<Company | Project> | null,
    name: any
  ) => {
    onFilterChange(value, name);
    onFilterChange(null, "contract");
  };

  const onCompanySelect = (value: SelectOption<Company> | null, name: any) => {
    onChange(value, name);

    if (!!value && value.item && value.item.nds) {
      const ndsValue = {
        value: value.item.nds.id,
        label: value.item.nds.name,
        item: value.item.nds,
      };
      onFilterChange(ndsValue, "nds");
      setSelectedCompanyNDS(ndsValue);
    }
  };

  const getContracts = (): Promise<PageableResponse<SmartContract>> => {
    const obj: ContractActParams = {
      customerId: filterParams?.organization?.value,
      projectId: filterParams?.project?.value,
      partnerId: filterParams?.partner?.value,
    };

    const valid = Object.values(obj).every(Boolean);

    if (!valid) {
      return Promise.resolve({
        errors: null,
        message: null,
        pageNumber: 1,
        pageSize: 10,
        recordsFiltered: 0,
        recordsTotal: 0,
        succeeded: true,
        data: [],
      });
    }
    return getContractsForAct(obj);
  };

  const loadCompanies = useCallback(async (params: any) => {
    try {
      const response = await getCompaniesFiltered(params);

      const formattedData = response.data.map((company: any) => {
        return {
          value: company.id,
          label:
            `${company.name} ${company.type?.name ?? ""}` +
            ` ${company.bin ? `(${company.bin})` : ""}`,
          item: company,
        };
      });

      setCompanies(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка компаний: ", error);
    }
  }, []);

  const loadMyCompanies = useCallback(async (params: any) => {
    try {
      const response = await getMyCompanies(params);

      const formattedData = response.data.map((company: any) => {
        return {
          value: company.id,
          label:
            `${company.name} ${company.type?.name ?? ""}` +
            ` ${company.bin ? `(${company.bin})` : ""}`,
          item: company,
        };
      });

      setMyCompanies(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка компаний: ", error);
    }
  }, []);

  const loadCurrencies = useCallback(async (params: any) => {
    try {
      const response = await getCurrencies(params);

      const formattedData = response.data.map((currency: Currency) => {
        return {
          value: currency.id,
          label: currency.name,
          item: currency,
        };
      });

      const kztIndex = formattedData.findIndex(
        (currency) => currency.label === "KZT"
      );
      if (kztIndex > -1) {
        const [kztCurrency] = formattedData.splice(kztIndex, 1);
        formattedData.unshift(kztCurrency);
      }

      setCurrencies(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка компаний: ", error);
    }
  }, []);

  const loadPartners = useCallback(async (params: any) => {
    try {
      const response = await getCompaniesFiltered(params);

      const formattedData: any = response.data.map((partner: any) => {
        return {
          value: partner.id,
          label: `${partner.name} ${partner.bin ? `(${partner.bin})` : ""}`,
          item: partner,
        };
      });

      setPartners(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка компаний: ", error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await loadMyCompanies(initialParams);
      await loadPartners(initialParams);
      await loadCompanies(initialParams);
      await loadMyCompanies(initialParams);
      await loadCurrencies(initialParams);
    })();
  }, [
    loadMyCompanies,
    loadPartners,
    loadCompanies,
    loadMyCompanies,
    loadCurrencies,
  ]);

  return (
    <div>
      <Header>
        <Grid columns={4}>
          <Select<any>
            label="Организация"
            name="organization"
            onChange={onChange}
            value={filterParams?.organization}
            error={!!errors.organization}
            helperText={errors.organization}
            valueKey="id"
            labelKey="name"
            options={isDispatcher(user!.role) ? companies : myCompanies}
          />
          <Select<Project>
            label="Проект"
            name="project"
            onChange={onChange}
            value={filterParams?.project}
            error={!!errors.project}
            helperText={errors.project}
            valueKey="id"
            labelKey="name"
            loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
          />
          <DateTimePicker
            label="Дата начала"
            name="startDate"
            onChange={onFilterChange}
            value={filterParams?.startDate}
            error={!!errors.startDate}
            helperText={errors.startDate}
            hideTime={true}
          />
          <DateTimePicker
            label="Дата завершения"
            name="endDate"
            onChange={onFilterChange}
            value={filterParams?.endDate}
            error={!!errors.endDate}
            helperText={errors.endDate}
            hideTime={true}
          />
          <Select<any>
            label="Контрагент"
            name="partner"
            onChange={onCompanySelect}
            value={filterParams?.partner}
            error={!!errors.partner}
            helperText={errors.partner}
            valueKey="id"
            labelKey="name"
            options={myPartners}
          />
          <Select<any>
            label="Тип техники"
            name="vehicleType"
            onChange={onChange}
            value={filterParams?.vehicleType}
            error={!!errors.vehicleType}
            helperText={errors.vehicleType}
            valueKey="id"
            labelKey="name"
            options={vehicleTypes}
          />
          <Select<SmartContract>
            label="Договор"
            name="contract"
            onChange={onFilterChange}
            value={filterParams?.contract}
            error={!!errors.contract}
            helperText={errors.contract}
            valueKey="id"
            labelKey="name"
            loadData={getContracts}
            disabled={
              !filterParams?.organization ||
              !filterParams?.partner ||
              !filterParams?.project
            }
          />
          <Grid columns={2}>
            <Select<NdsShort>
              label="Ставка НДС"
              name="nds"
              onChange={onFilterChange}
              value={filterParams?.nds}
              error={!!errors.nds}
              helperText={errors.nds}
              valueKey="id"
              labelKey="name"
              options={selectedCompanyNDS ? [selectedCompanyNDS] : []}
            />
            <Select<Currency>
              label="Валюта"
              name="currency"
              onChange={onFilterChange}
              value={filterParams?.currency}
              error={!!errors.currency}
              helperText={errors.currency}
              valueKey="id"
              labelKey="name"
              options={currencies}
            />
          </Grid>
        </Grid>
      </Header>
    </div>
  );
}

export default memo(Step1);
