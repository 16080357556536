import { LayoutDefault } from "@app/layouts";
import React, { memo, useMemo } from "react";
import { ChipRouter, TabRoute, TabRouter } from "@app/common";
import { VAERequests, VehicleRequests } from "./components";
import { Route, Routes } from "react-router-dom";
import { ChipRoute } from "../../common/ChipRouter/ChipRouter";
import PageVehicleProlongation from "../VehicleProlongation";

function PageVehiclesInProject() {
  const routes = useMemo<TabRoute[]>(
    () => [
      {
        label: "Заказ на технику",
        path: "/vehicles-in-project/requests",
      },
      {
        label: "Продление работы техники",
        path: "/vehicles-in-project/prolongation",
      },
    ],
    []
  );

  const chipRoutes = useMemo<ChipRoute[]>(
    () => [
      {
        label: "Заказ на технику",
        path: "/vehicles-in-project/requests",
      },
      {
        label: "Заказ на доп затраты",
        path: "/vehicles-in-project/vae-requests",
      },
    ],
    []
  );

  return (
    <LayoutDefault title="Заказ техники">
      <TabRouter routes={routes}>
        <Routes>
          <Route path="/prolongation" element={<PageVehicleProlongation />} />
          <Route
            path="*"
            element={
              <ChipRouter routes={chipRoutes}>
                <Routes>
                  <Route path="/requests" element={<VehicleRequests />} />
                  <Route path="/vae-requests" element={<VAERequests />} />
                </Routes>
              </ChipRouter>
            }
          />
        </Routes>
      </TabRouter>
    </LayoutDefault>
  );
}

export default memo(PageVehiclesInProject);
