import React, { memo, ReactNode } from "react";
import { Button, Modal } from "@app/components";
import styled from "styled-components";
import { theme } from "styled-tools";

type Props = {
  open: boolean;
  title: string;
  subtitle?: string;
  loading?: boolean;
  confirmLabel?: string;
  onClose: () => void;
  onConfirm: () => void;
  children?: ReactNode;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const StyledTitle = styled.div`
  display: flex;
`;

const StyledSubtitle = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: ${theme("color.grayDark")};
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledActions = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-top: 1px solid ${theme("color.grayLight")};
`;

const ModalDuplication = (props: Props) => {
  const {
    open,
    title,
    subtitle,
    children,
    loading,
    onClose,
    onConfirm,
    confirmLabel = "Удалить",
  } = props;

  return (
    <Modal
      open={open}
      title={title}
      position="center"
      customSize={"400px"}
      hideHeader
      size={"medium"}
      dense={true}
      zIndex={10000}
      onClose={onClose}
    >
      <StyledContainer>
        <StyledTitle>
          <h3>{title}</h3>
        </StyledTitle>
        {!!subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        {!!children && <StyledContent>{children}</StyledContent>}
      </StyledContainer>

      <StyledActions>
        <Button text="Отмена" variant="outlined" onClick={onClose} />
        <Button text={confirmLabel} showLoader={loading} onClick={onConfirm} />
      </StyledActions>
    </Modal>
  );
};

export default memo(ModalDuplication);
