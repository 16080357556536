import { PageableParams, PageableResponse } from "./interfaces";
import { Characteristic } from "@app/models";
import instance from "./instance";

export function getCharacteristics(
  params: PageableParams<Characteristic> & {
    typeIds?: number[];
    vehicleTypeId: string;
  }
): Promise<PageableResponse<Characteristic>> {
  return instance()
    .get("/characteristic", {
      params,
    })
    .then((response) => response.data);
}
