import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BaseButton,
  Button,
  Grid,
  ListItem,
  Modal,
  ModalProps,
  ModalTitle,
  Table,
  Tabs,
  Tooltip,
} from "@app/components";
import styled from "styled-components";
import {
  IconChevronDown24,
  IconChevronUp24,
  IconDay24,
  IconNight24,
  IconPlay24,
  IconPrint16,
} from "@app/icons";
import {
  currencyFormat,
  dateFormat,
  excludeVAT,
  getActOfWorkNomenclatureName,
  getActOfWorkNomenclaturePrice,
  getActOfWorkNomenclatureQuantity,
  isPartner,
  joinValues,
  NIGHT_SHIFT_ID,
  roundToTwo,
} from "@app/helpers";
import {
  Act,
  ActOfWork,
  ActStatus,
  ActVehicleItemV2,
  Dictionary,
  KIPVehicleDetails,
  SmartContract,
  SmartContractStatus,
  VehicleJournalForActV2,
} from "@app/models";
import {
  getActById,
  getActOfProvidedWorkBasisList,
  getVehiclesByActIdV2,
} from "@app/api";
import { format } from "date-fns";
import { theme } from "styled-tools";
import { actTableLabels } from "./helper";
import {
  ModalActOfServicesRevoke,
  ModalActOfWorkDetail,
  ModalContractDocDetail,
  ModalKIPVehicleTrack,
} from "../index";
import ActApprovalListView from "../ActTaskDetail/components/ApprovalList";
import { SmartContractStatusBadge } from "@app/common";
import { useUser } from "@app/providers";
import TableRowFile from "../../common/TableRowFile";

interface Props extends Omit<ModalProps, "title"> {
  data: Act | null;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
  background: #f5f6f8;
  border-bottom: 1px solid #d8d8d8;
`;

const Container = styled.div`
  flex: 1;
  overflow: auto;
`;

const TableContainer = styled.div`
  margin: 16px;

  h2 {
    padding: 8px 16px;
  }
`;

const ParentCell = styled.td`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 12px;
  width: 1%;
  white-space: nowrap;
  text-align: left;
  border-top: 1px solid ${theme("color.grayLight")};
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  background-color: ${theme("color.blueLight")};
`;

const WrappedCell = styled.div`
  max-width: 340px;
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
`;

const StyledButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${theme("color.orange")};
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
  &:disabled {
    opacity: 1 !important;
    transform: scale(1);
    cursor: default;
    color: ${theme("color.gray")};
    box-shadow: none;
  }
`;

const StyledContractTitle = styled.p<{ clickable: boolean }>`
  text-align: start;
  text-decoration: ${(props) => (props.clickable ? "underline" : "initial")};
`;

const StyledPlayBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: ${theme("color.green")};
  cursor: pointer;
  border-radius: 50%;
`;

const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  text-decoration: none;
  border-radius: 100px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  background: linear-gradient(69deg, #ed4a3f 0%, #f99335 100%);
  &:disabled {
    background: ${theme("color.grayLight")};
  }
`;

function ModalActOfServicesDetail(props: Props) {
  const { onClose, data, ...restProps } = props;
  const { user } = useUser();
  const [pending, setPending] = useState<boolean>(true);
  const [pendingActOfWork, setPendingActOfWork] = useState<boolean>(false);
  const [act, setAct] = useState<Act | null>(data);
  const [actVehicles, setActVehicles] = useState<VehicleJournalForActV2[]>(
    data?.actVehicleGroupDto ?? []
  );
  const [collapsedRows, setCollapsedRows] = useState<number[]>([]);
  const [actOfWork, setActOfWork] = useState<ActOfWork | null>(null);
  const [contractId, setContractId] = useState<SmartContract["id"] | null>(
    null
  );
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [selectedAct, setSelectedAct] = useState<ActVehicleItemV2>();
  const [vehicleDetails, setVehicleDetails] =
    useState<KIPVehicleDetails | null>(null);

  const isActAgreed = useMemo(
    () =>
      act &&
      [
        ActStatus.Agreed,
        ActStatus.ToBeAgreed,
        ActStatus.AvrRevoked,
        ActStatus.ActSigningRejected,
      ].indexOf(act.status) > -1,
    [act]
  );

  const isContractSigned = useMemo(
    () => data?.contract.status === SmartContractStatus.Signed,
    [data?.contract.status]
  );

  const isActNotAgreed = useMemo(
    () => data?.status !== ActStatus.Agreed,
    [data?.status]
  );

  const onClickCreateAvr = useCallback(async () => {
    try {
      if (!act) {
        return;
      }

      setPendingActOfWork(true);

      const response = await getVehiclesByActIdV2(act.id, true);
      const basisListResponse = await getActOfProvidedWorkBasisList();

      setActOfWork({
        id: "",
        createdDate: new Date().toString(),
        number: null,
        registrationNumber: "",
        date: null,
        startDate: act.startWorkDate,
        endDate: act.endWorkDate,
        senderCompanyAddress: "",
        recipientCompanyAddress: "",
        ndsRate: act.nds.name,
        statusId: null,
        actId: act.id,
        senderCompany: act.partner,
        recipientCompany: act.company,
        project: act.project,
        contract: act.contract as Dictionary,
        basis: basisListResponse.data.find((item) => item.isActBasis) || null,
        signedFile: null,
        totalQuantity: null,
        totalSum: 0,
        totalSumNds: 0,
        files: [],
        signers: [],
        histories: [],
        nomenclatures: response.data.map((item) => ({
          avrId: "",
          name: getActOfWorkNomenclatureName(item),
          executionDate: act.endWorkDate,
          unit: item.unitDto,
          quantity: getActOfWorkNomenclatureQuantity(item),
          price: getActOfWorkNomenclaturePrice(item),
          sum: item.totalSumma,
          sumNds: item.totalSummaNds || 0,
        })),
        approvers: [],
      });

      setPendingActOfWork(false);
    } catch (e) {
      setPendingActOfWork(false);
    }
  }, [act]);

  const onCloseActOfWorkModal = useCallback(() => {
    setActOfWork(null);
  }, []);

  const onContractDetailClose = useCallback(() => {
    setContractId(null);
  }, []);

  const showContractDetail = useCallback(async () => {
    if (!data?.contract.contractDraftId) {
      return;
    }

    setContractId(data.contract.contractDraftId);
  }, [data?.contract.contractDraftId]);

  const onPrintButtonClick = useCallback(() => {
    if (!data) {
      return;
    }
    window.open(
      `./act-of-provided-services/printing/${data.id}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, [data]);

  /*const hidePrintButton = useMemo(() => {
    return (
      userIsPartner &&
      data?.status &&
      [
        ActStatus.Created,
        ActStatus.ToBeAgreed,
        ActStatus.Rejected,
        ActStatus.ActSigningRejected,
        ActStatus.ActSigningOverdue,
        ActStatus.Deleted,
        ActStatus.AvrRevoked,
        ActStatus.AvrDeleted,
      ].indexOf(data.status) > -1
    );
  }, [data, userIsPartner]);*/

  const modalTitle = useMemo<ReactNode>(() => {
    if (!data) {
      return "";
    }

    if (pending) {
      return "Загружается...";
    }

    return (
      <ModalTitle text={`Табель оказанных услуг №${data.actNumber}`}>
        <Tooltip
          direction={"right"}
          content={
            isActNotAgreed
              ? "Табель должен быть согласован заказчиком для создания АВР"
              : !isContractSigned
              ? "Договор еще не подписан. Обратитесь к ответственному лицу"
              : ""
          }
        >
          <Button
            text="Создать АВР"
            size="small"
            showLoader={pendingActOfWork}
            disabled={pendingActOfWork || !isContractSigned || isActNotAgreed}
            onClick={onClickCreateAvr}
          />
        </Tooltip>
        {/* попросили убрать условие */}
        {!!data.status /*&& !hidePrintButton*/ && (
          <Button
            text={"Печатная форма"}
            size={"small"}
            variant={"outlined"}
            startIcon={IconPrint16}
            onClick={onPrintButtonClick}
          />
        )}
      </ModalTitle>
    );
  }, [
    data,
    isActNotAgreed,
    isContractSigned,
    onClickCreateAvr,
    onPrintButtonClick,
    pending,
    pendingActOfWork,
  ]);

  const getActData = useCallback((id: Act["id"]) => {
    getActById(id)
      .then(({ succeeded, data: actData }) => {
        setPending(false);

        if (!succeeded) {
          // setError(true);
          return;
        }

        setAct(actData);
        if (actData.actVehicleGroupDto) {
          setActVehicles(actData.actVehicleGroupDto);
        }
      })
      .catch(() => {
        setPending(false);
      });
  }, []);

  const onPlayClick = useCallback(
    (act: ActVehicleItemV2) => () => {
      setSelectedAct(act);
      setVehicleDetails({
        workStartDateTime: act.startTime,
        workEndDateTime: act.endTime,
      } as KIPVehicleDetails);
    },
    []
  );

  const onCloseTrackModal = useCallback(() => {
    setVehicleDetails(null);
  }, []);

  const onRevokeModalClose = useCallback(
    (refresh?: boolean) => {
      if (refresh && data?.id) {
        getActData(data.id);
      }
      setShowRevokeModal(false);
    },
    [data?.id, getActData]
  );

  useEffect(() => {
    if (act?.id) {
      getActData(act.id);
    }
  }, [act?.id, getActData]);

  const getMapTableData = useCallback(
    (array: ActVehicleItemV2[]) => {
      return array.map((item, index) => {
        if (!item) {
          return [];
        }

        return [
          <StyledPlayBtn onClick={onPlayClick(item)}>
            <IconPlay24 />
          </StyledPlayBtn>,
          `${index + 1}`,
          `${dateFormat(item.workDate, "dd.MM.yyyy")}`,
          item.shiftTypeDto?.id === NIGHT_SHIFT_ID ? (
            <IconNight24 />
          ) : (
            <IconDay24 />
          ),
          joinValues([
            dateFormat(item.startTime, "HH:mm"),
            dateFormat(item.endTime, "HH:mm"),
          ]),
          <WrappedCell>
            {joinValues([item.vehicleTypeDto, item.nomenclature])}
          </WrappedCell>,
          item.characteristic?.name,
          item.serviceType?.name,
          item.govNumber,
          item.workPerformed,
          item.hoursGps ? item.hoursGps.toString().slice(0, -3) : 0,
          item.shiftDuration ? item.shiftDuration.slice(0, -3) : 0,
          item.unitDto?.name,
          currencyFormat(item.price),
          item.toPay,
          currencyFormat(roundToTwo(item.summa)),
          currencyFormat(roundToTwo(item.summaNds)),
          currencyFormat(roundToTwo(item.summa)), // total
          item.comment,
          <TableRowFile files={item.files} />,
        ];
      });
    },
    [onPlayClick]
  );

  const reduceTableData = useCallback(
    (item: VehicleJournalForActV2) => {
      if (item && item.items) {
        return getMapTableData(item.items);
      }

      return [];
    },
    [getMapTableData]
  );

  const mapTableCollapsedData = useCallback((item: VehicleJournalForActV2) => {
    if (!item) {
      return [];
    }

    return [
      <ParentCell>
        <IconChevronDown24 />
      </ParentCell>,
      <ParentCell colSpan={3} style={{ minWidth: "170px" }}>
        Итого по ед. изм.
      </ParentCell>,
      <ParentCell />,
      <ParentCell>{item.vehicleTypeName}</ParentCell>,
      <ParentCell>{item.characteristic?.name}</ParentCell>,
      <ParentCell />,
      <ParentCell>{item.govNumber}</ParentCell>,
      <ParentCell>{item.totalWorkPerformed}</ParentCell>,
      <ParentCell>{item.totalHoursGps}</ParentCell>,
      <ParentCell>{item.totalShiftDuration}</ParentCell>,
      <ParentCell>{item.unitDto?.name}</ParentCell>,
      <ParentCell />,
      <ParentCell>{currencyFormat(item.totalToPay)}</ParentCell>,
      <ParentCell>{currencyFormat(item.totalSumma)}</ParentCell>,
      <ParentCell>{currencyFormat(item.totalSummaNds)}</ParentCell>,
      <ParentCell>{currencyFormat(item.totalSumma)}</ParentCell>,
      <ParentCell />,
      <ParentCell />,
    ];
  }, []);

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => actVehicles.map(mapTableCollapsedData),
    [actVehicles, mapTableCollapsedData]
  );

  const tableDataChildren = useMemo<(string | ReactNode)[][][]>(
    () => actVehicles.map(reduceTableData),
    [actVehicles, reduceTableData]
  );

  const reduceItemsSum = useCallback(
    (vehicleItems: ActVehicleItemV2[] | null, key: keyof ActVehicleItemV2) => {
      if (!vehicleItems) return 0;

      return vehicleItems.reduce((sum, item) => {
        if (key === "summaNds") {
          return sum + excludeVAT(item.summa, act?.nds?.rate);
        }

        return sum + ((item[key] || 0) as number);
      }, 0);
    },
    [act?.nds?.rate]
  );

  const reduceSum = useCallback(
    (key: keyof ActVehicleItemV2) => {
      const summa = actVehicles.reduce((acc, cur) => {
        return acc + reduceItemsSum(cur.items, key);
      }, 0);

      return currencyFormat(roundToTwo(summa));
    },
    [actVehicles, reduceItemsSum]
  );

  const onParentRowExpand = useCallback(() => {
    if (collapsedRows.length) {
      setCollapsedRows([]);
    } else {
      setCollapsedRows(tableData.map((_, i) => i));
    }
  }, [collapsedRows.length, tableData]);

  const tableExtraRow = useMemo<(string | ReactNode)[]>(
    () =>
      actTableLabels.map((_, index) => {
        if (index === 0) {
          return (
            <StyledButton onClick={onParentRowExpand}>
              {collapsedRows.length ? (
                <IconChevronDown24 />
              ) : (
                <IconChevronUp24 />
              )}
            </StyledButton>
          );
        } else if (index === 1) {
          return "Итого";
        } else if (index === 15) {
          return reduceSum("summa");
        } else if (index === 16) {
          return reduceSum("summaNds");
        } else if (index === 17) {
          return reduceSum("summa"); // total
        } else if (index === 19) {
          const itemsMap = actVehicles.flatMap((item) => item.items);
          const filesExist = itemsMap.some((item) => item?.files?.length);
          return filesExist ? (
            <DownloadButton onClick={() => {}}>Скачать все</DownloadButton>
          ) : null;
        }
        return "";
      }),
    [actVehicles, collapsedRows.length, onParentRowExpand, reduceSum]
  );

  const tabLabels = useMemo<string[]>(() => {
    const tabs = ["Детализация работ"];
    // Скрыть для партнера 'Лист согласования'
    return isPartner(user!.role) ? tabs : [...tabs, "Лист согласования"];
  }, [user]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];

    if (isActAgreed) {
      returnActions.push(
        <Button
          text="Отозвать"
          variant={"outlined"}
          onClick={() => {
            setShowRevokeModal(true);
          }}
        />
      );
    }
    return returnActions;
  }, [isActAgreed]);

  return (
    <>
      <Modal
        title={modalTitle}
        actions={actions}
        dense
        onClose={onClose}
        {...restProps}
      >
        <Header>
          <Grid columns={4}>
            <ListItem title={act?.company.name} subtitle={"Организация"} />
            <ListItem title={act?.partner.name} subtitle={"Контрагент"} />
            <ListItem
              title={
                act?.createdDate
                  ? format(new Date(act.startWorkDate), "dd.MM.yyyy")
                  : ""
              }
              subtitle={"Дата начала"}
            />
            <ListItem title={act?.nds?.name} subtitle={"Ставка НДС"} />
            <ListItem title={act?.project?.name} subtitle={"Проект"} />
            {act && (
              <ListItem
                title={
                  <SmartContractStatusBadge status={act.contract.status}>
                    <BaseButton
                      disabled={!act?.contract.contractDraftId}
                      onClick={showContractDetail}
                    >
                      <StyledContractTitle
                        clickable={!!act?.contract.contractDraftId}
                      >
                        {act?.contract?.name}
                      </StyledContractTitle>
                    </BaseButton>
                  </SmartContractStatusBadge>
                }
                subtitle={"Договор"}
              />
            )}
            <ListItem
              title={
                act?.endWorkDate
                  ? format(new Date(act.endWorkDate), "dd.MM.yyyy")
                  : ""
              }
              subtitle={"Дата завершения"}
            />
          </Grid>
        </Header>
        <Container>
          <Tabs labels={tabLabels}>
            <TableContainer>
              {tableData.length ? (
                <React.Fragment>
                  <h2>Техника</h2>
                  <Table
                    labels={actTableLabels}
                    collapsedData={tableData}
                    nodeData={tableDataChildren}
                    extraRow={tableExtraRow}
                    collapsedRows={collapsedRows}
                    disableOverflow
                    onClick={() => {}}
                    onRowCollapse={(val) => setCollapsedRows(val)}
                  />
                </React.Fragment>
              ) : (
                <p>Список техник пуст</p>
              )}
            </TableContainer>
            <TableContainer>
              <ActApprovalListView actId={act?.id} />
            </TableContainer>
          </Tabs>
        </Container>
      </Modal>
      <ModalActOfWorkDetail
        actOfWork={actOfWork}
        onClose={onCloseActOfWorkModal}
        open={!!actOfWork}
        isActOfService={true}
      />
      <ModalContractDocDetail
        open={!!contractId}
        contractId={contractId}
        size={"medium"}
        onClose={onContractDetailClose}
      />
      <ModalActOfServicesRevoke
        open={showRevokeModal}
        title="Отозвать табель"
        actId={data?.id}
        onClose={onRevokeModalClose}
      />
      {selectedAct && (
        <ModalKIPVehicleTrack
          open={!!vehicleDetails}
          projectId={act?.project.id}
          vehicleDetails={vehicleDetails}
          vehicleId={selectedAct.vehicleId}
          vehicleGovNumber={selectedAct.govNumber}
          onClose={onCloseTrackModal}
        />
      )}
      {/*<ModalSmartContractDetail
        open={contractDetailVisible}
        data={contract as SmartContract}
        onClose={onContractDetailClose}
      />*/}
    </>
  );
}

export default memo(ModalActOfServicesDetail);
