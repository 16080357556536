import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Checkbox,
  DateTimeCalendar,
  Dialog,
  Loader,
  Pagination,
  Select,
  SelectOption,
  Table,
  TextField,
  useForm,
} from "@app/components";
import {
  APIResponse,
  getProjects,
  getUnits,
  getVehicleRequestOffers,
  getVehiclesRequests,
  getWorkShifts,
  onVehicleRequestDuplication,
  onVehicleRequestProlongation,
  PageableParams,
} from "@app/api";
import {
  IconDay24,
  IconFilter24,
  IconMore24,
  IconNight24,
  IconVehicleTire56,
  IconWarningCircle24,
} from "@app/icons";
import "./styles.scss";
import {
  dateFormat,
  getAxiosErrorMessage,
  NIGHT_SHIFT_ID,
  priceFormat,
} from "@app/helpers";
import { useNotification } from "@app/providers";
import { AxiosError } from "axios";
import { ifProp, switchProp, theme } from "styled-tools";
import { Unit, WorkShift } from "@app/models";
import ModalDuplication from "./components/ModalDuplication";
import { VehiclesFilterParams } from "./components/Filter";
import { VehiclesProlongationFilter } from "./components";

const StyledSidebar = styled.div`
  position: absolute;
  left: 16px;
  top: 70px;
  width: 335px;
  height: 920px;
  background-color: #ffffff;
  z-index: 2;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const StyledHeaderFilter = styled.div<{ visible: boolean }>`
  position: relative;
  overflow: ${ifProp("visible", "visible", "hidden")};
`;

const StyledMainContent = styled.div`
  display: flex;
  margin-left: 350px;
  height: 920px;
`;

const StyledHeaderText = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
`;

const StyledVehiclesFilter = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 100%;
  overflow: ${ifProp("visible", "visible", "hidden")};
`;

const StyledLoaderContainer = styled.div<{ absolute: boolean }>`
  ${switchProp("absolute", {
    false: css`
      text-align: center;
      padding: 20px;
      box-sizing: border-box;
    `,
    true: css`
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1;
    `,
  })}
`;

const StyledVehicles = styled.div<{ overflowHidden: boolean }>`
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
`;

const StyledPagination = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 52px;
  width: 100%;
  border-top: 1px solid ${theme("color.grayLight")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme("color.white")};
`;

const ListItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${theme("color.grayLight")};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ResetButton = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const StyledResetButton = styled.span`
  font-size: 14px;
  margin-left: 8px;
  color: ${theme("color.grayDark")};
`;

const StyledSearchBar = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
  margin-top: 8px;
`;

const StyledTableContainer = styled.div`
  flex-grow: 1;
  padding: 16px;
  overflow-y: scroll;
  margin-left: 30px;
  width: 100%;
  height: 920px;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${theme("color.grayDark")};
  font-size: 16px;
  padding: 20px;
  height: 100%;
`;

const StyledEmptyPanelText = styled.span`
  font-size: 16px;
  color: ${theme("color.gray")};
`;

const StyledModalWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledModal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  top: 25px;
  right: 0;
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const ModalButton = styled.button`
  outline: none;
  background: none;
  border: none;
  align-content: center;
  align-items: center;
  height: 44px;
  cursor: pointer;
`;

const StyledCalendarModal = styled.div`
  position: absolute;
  display: flex;
  padding: 16px;
  width: 304px;
  height: 544px;
  top: 76px;
  right: 120px;
`;

interface VehicleProlongationProps {}

interface Data {
  endDate: Date | null;
  workShift: SelectOption<WorkShift> | null;
  unit: SelectOption<Unit> | null;
}

function PageVehicleProlongation(props: VehicleProlongationProps) {
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(true);
  const [pendingVehicle, setPendingVehicle] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [searchText, setSearchText] = useState<string>("");
  const [vehicleDetails, setVehicleDetails] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showProlongationDialog, setShowProlongationDialog] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [dateTimePickerVisible, setDateTimePickerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const modalDatePickerRef = useRef<HTMLDivElement>(null);
  const prevSelectedItemsRef = useRef<Set<string>>(new Set());
  const ref = useRef<HTMLDivElement>(null);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [filteredCount, setFilteredCount] = useState<number>(0);
  const [dataSource, setDataSource] = useState<string>("");

  const { onChange, values } = useForm<Data>({
    values: {
      endDate: null,
      workShift: null,
      unit: null,
    },
  });

  const defaultFilter = useMemo<VehiclesFilterParams>(() => {
    return {
      numbers: "",
      status: null,
      organization: null,
      project: null,
      vehicleType: null,
      govNumber: "",
      serviceType: null,
      startDate: null,
      endDate: null,
    };
  }, []);

  const filterParams = useRef<VehiclesFilterParams>(defaultFilter);

  const onChangePage = useCallback(
    async (newPage: number) => {
      setPage(newPage);
      await getData(newPage, searchText);
    },
    [searchText]
  );

  const onClickFilter = useCallback(() => {
    setFilterVisible((prevFilterVisible) => !prevFilterVisible);
  }, []);

  const hideFilter = useCallback(() => {
    if (filterVisible) {
      setFilterVisible(false);
    }
  }, [filterVisible]);

  const onClickEdit = useCallback(() => {
    if (selectedItem) {
      setModalVisible(false);
      setShowProlongationDialog(false);
      setDateTimePickerVisible(true);
    }
  }, [selectedItem]);

  const onClickDuplicate = useCallback(() => {
    if (selectedItem) {
      setShowDialog(true);
    }
  }, [selectedItem, setShowDialog]);

  const onClickMoreIcon = useCallback((item: any) => {
    setSelectedItem(item);
    setModalVisible(true);
  }, []);

  const getData = useCallback(
    async (pageNumber: number = 1, searchText: string = "") => {
      setPending(true);

      const response = await getProjects({
        pageNumber,
        pageSize: 15,
        searchText,
      });

      if (response.succeeded) {
        setData(response.data);
        setTotalCount(response.recordsTotal);
        setFilteredCount(0);
      }

      setPending(false);
    },
    []
  );

  const onConfirmDuplication = useCallback(async () => {
    if (selectedItem) {
      const duplicationData = {
        id: selectedItem.request?.id,
        unitId: values.unit!.value,
        workShiftId: values.workShift!.value,
      };

      try {
        const response = await onVehicleRequestDuplication(duplicationData);
        if (response && response.succeeded) {
          setShowDialog(false);
          showNotification({
            message: "Заказ продублирован на ночную смену",
            variant: "success",
          });
        }
      } catch (error) {
        showNotification({
          message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
          variant: "error",
        });
      } finally {
        setShowDialog(false);
        setShowProlongationDialog(false);
        setModalVisible(false);
        getData();
      }
    } else {
      setShowDialog(false);
      setShowProlongationDialog(false);
      setModalVisible(false);
      showNotification({
        message: "Не выбран элемент",
        variant: "error",
      });
    }
  }, [selectedItem, showNotification, setShowDialog, getData, values]);

  const onCloseDuplicationModal = useCallback(() => {
    setShowDialog(false);
  }, []);

  const onConfirmProlongation = useCallback(async () => {
    if (selectedItem && selectedDate) {
      const offset = selectedDate.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(selectedDate.getTime() - offset);
      const formattedEndDate = adjustedDate.toISOString().replace(".000Z", "Z");

      const data = {
        id: selectedItem.request?.id,
        endDate: formattedEndDate,
      };

      try {
        const response = await onVehicleRequestProlongation(data);
        if (response && response.succeeded) {
          showNotification({
            message: "Заказ продлен",
            variant: "success",
          });
        }
      } catch (error) {
        showNotification({
          message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
          variant: "error",
        });
      } finally {
        setShowProlongationDialog(false);
        getData();
      }
    } else {
      setShowProlongationDialog(false);
      showNotification({
        message: "Не выбран элемент или дата",
        variant: "error",
      });
    }
  }, [selectedItem, selectedDate, showNotification, getData]);

  const onCloseProlongationDialog = useCallback(() => {
    setShowProlongationDialog(false);
  }, []);

  const onDateChange = useCallback((newDate: Date | null, name: string) => {
    setSelectedDate(newDate);
    setDateTimePickerVisible(false);
    setShowProlongationDialog(true);
  }, []);

  const onCloseDateTimeCalendar = useCallback(() => {
    setDateTimePickerVisible(false);
  }, []);

  const getVehicleDetails = useCallback(async (ids: string[], params = {}) => {
    setPendingVehicle(true);

    try {
      const response = await getVehicleRequestOffers({
        pageNumber: 1,
        pageSize: 20,
        projectIds: [ids],
        ...params,
      });

      if (response.succeeded) {
        const details = response.data;
        setVehicleDetails((prevDetails) => [
          ...prevDetails.filter(
            (detail) => !ids.includes(detail.request.project.id)
          ),
          ...details,
        ]);
      }
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    } finally {
      setPendingVehicle(false);
    }
  }, []);

  const onSubmitFilter = useCallback(
    async (values: VehiclesFilterParams) => {
      hideFilter();
      filterParams.current = values;

      const requestParams: any = {
        startDate: values.startDate,
        endDate: values.endDate,
        numbers: values.numbers ? values.numbers.split(",").map(Number) : [],
        status: values.status ? [values.status.value] : [],
      };

      const offersParams: any = {
        govNumber: values.govNumber ? values.govNumber : "",
        serviceType: values.serviceType ? [values.serviceType.value] : [],
        vehicleType: values.vehicleType ? [values.vehicleType.value] : [],
        project: values.project ? [values.project.value] : [],
      };

      setPending(true);
      const selectedProjectIds = Array.from(selectedItems);

      if (
        requestParams.numbers.length > 0 ||
        requestParams.status.length > 0 ||
        requestParams.startDate ||
        requestParams.endDate
      ) {
        // Если параметры фильтрации относятся к getVehiclesRequests
        const response = await getVehiclesRequests(requestParams);
        if (response.succeeded) {
          setVehicleDetails(response.data);
          setDataSource("vehiclesRequests");
        }
      } else if (
        offersParams.govNumber ||
        offersParams.serviceType.length > 0 ||
        offersParams.vehicleType.length > 0 ||
        offersParams.project.length > 0
      ) {
        // Если параметры фильтрации относятся к getVehicleRequestOffers
        if (selectedProjectIds.length > 0) {
          await getVehicleDetails(selectedProjectIds, offersParams);
        } else {
          const response = await getVehicleRequestOffers(offersParams);
          if (response.succeeded) {
            setVehicleDetails(response.data);
            setDataSource("vehicleRequestOffers");
          }
        }
      }

      setPending(false);
    },
    [selectedItems, hideFilter, getVehicleDetails]
  );

  const toggleSelectItem = useCallback(
    async (id: string) => {
      setSelectedItems((prev) => {
        const newSelectedItems = new Set(prev);
        let isAdding = false;
        if (newSelectedItems.has(id)) {
          newSelectedItems.delete(id);
        } else {
          newSelectedItems.add(id);
          isAdding = true;
        }

        if (isAdding) {
          // Извлечь и добавить данные выбранном элементе
          getVehicleDetails([id], { isAdding: true });
        } else {
          // Удалить данные элементе
          setVehicleDetails((prevDetails) =>
            prevDetails.filter((detail) => detail.request.project.id !== id)
          );
        }

        return newSelectedItems;
      });
    },
    [getVehicleDetails]
  );

  useEffect(() => {
    const selectedProjectIds = Array.from(selectedItems);
    if (selectedProjectIds.length > 0) {
      getVehicleDetails(selectedProjectIds);
    } else {
      setVehicleDetails([]);
    }
  }, [selectedItems, getVehicleDetails]);

  const loadSelectedDetails = useCallback(async () => {
    const idsToFetch = Array.from(selectedItems);

    if (idsToFetch.length > 0) {
      await getVehicleDetails(idsToFetch);
    } else {
      setVehicleDetails([]);
    }
  }, [selectedItems, getVehicleDetails]);

  const onClickOutside = useCallback((e: MouseEvent) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target as HTMLElement)
    ) {
      setModalVisible(false);
    }

    if (
      modalDatePickerRef.current &&
      !modalDatePickerRef.current.contains(e.target as HTMLElement)
    ) {
      setDateTimePickerVisible(false);
    }

    if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
      setFilterVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);
    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    getData(page, searchText);
  }, [page, searchText, getData]);

  const resetSelection = useCallback(() => {
    setSelectedItems(new Set());
    setVehicleDetails([]);
    prevSelectedItemsRef.current = new Set();
    setFilteredCount(0);
  }, []);

  const isChecked = (id: string) => selectedItems.has(id);

  const minDate = new Date(2025, 0, 1);
  const maxDate = new Date(2025, 11, 31);

  const onSearchChange = useCallback((value: string) => {
    setSearchText(value);
    setPage(1);
  }, []);

  const loadUnits = useCallback((params: PageableParams<Unit>) => {
    return getUnits({
      ...params,
    });
  }, []);

  useEffect(() => {
    loadSelectedDetails();
  }, [selectedItems, loadSelectedDetails]);

  const toggleSelectAllItems = () => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.size === data.length) {
        return new Set();
      }
      return new Set(data.map((item) => item.id));
    });
  };

  const tableLabels = useMemo(() => {
    if (dataSource === "vehicleRequestOffers") {
      return [
        "Номер",
        "Организация",
        "Проект",
        "Гос номер",
        "Вид Техники",
        "Вид услуги",
        "Вид Характеристики",
        "Единица измерения",
        "Дата начала",
        "Дата завершения",
        "Цена",
        "Смена",
      ];
    } else if (dataSource === "vehiclesRequests") {
      return [
        "Номер",
        "Организация",
        "Проект",
        "Гос номер",
        "Вид Техники",
        "Вид услуги",
        "Вид Характеристики",
        "Единица измерения",
        "Дата начала",
        "Дата завершения",
        "Смена",
      ];
    } else {
      return [
        "Номер",
        "Организация",
        "Проект",
        "Гос номер",
        "Вид Техники",
        "Вид услуги",
        "Вид Характеристики",
        "Единица измерения",
        "Дата начала",
        "Дата завершения",
        "Цена",
        "Смена",
      ];
    }
  }, [dataSource]);

  const tableData = useMemo(() => {
    if (dataSource === "vehiclesRequests") {
      return vehicleDetails.map((detail) => [
        detail.number,
        detail.organization?.name,
        detail.project?.name,
        detail.vehicle?.govNumber,
        detail.vehicleType?.name,
        detail.serviceType?.name,
        detail.characteristics?.name,
        detail.unit.name,
        dateFormat(detail.startDate, "dd.MM.yyyy HH:mm"),
        dateFormat(detail.endDate, "dd.MM.yyyy HH:mm"),
        detail.workShift?.type?.code === NIGHT_SHIFT_ID ? (
          <IconNight24 />
        ) : (
          <IconDay24 />
        ),
        <StyledModalWrapper key={detail.id}>
          <IconMore24 onClick={() => onClickMoreIcon(detail)} />
          {modalVisible && selectedItem && selectedItem.id === detail.id && (
            <StyledModal ref={modalRef}>
              <ModalButton onClick={onClickEdit}>Продлить заказ</ModalButton>
              <ModalButton onClick={onClickDuplicate}>
                Дублировать на ночную смену
              </ModalButton>
            </StyledModal>
          )}
        </StyledModalWrapper>,
      ]);
    } else if (dataSource === "vehicleRequestOffers") {
      return vehicleDetails.map((detail) => [
        detail.request?.number,
        detail.request?.organization?.name,
        detail.request?.project?.name,
        detail.vehicle?.govNumber,
        detail.request?.vehicleType?.name,
        detail.request?.serviceType?.name,
        detail.request?.characteristics?.name,
        detail.request?.unit.name,
        dateFormat(detail.request?.startDate, "dd.MM.yyyy HH:mm"),
        dateFormat(detail.request?.endDate, "dd.MM.yyyy HH:mm"),
        priceFormat(
          detail.nds?.isNds
            ? detail.price.toString()
            : detail.priceWithoutNds.toString()
        ),
        detail.request?.workShift?.type?.code === NIGHT_SHIFT_ID ? (
          <IconNight24 />
        ) : (
          <IconDay24 />
        ),
        <StyledModalWrapper key={detail.id}>
          <IconMore24 onClick={() => onClickMoreIcon(detail)} />
          {modalVisible && selectedItem && selectedItem.id === detail.id && (
            <StyledModal ref={modalRef}>
              <ModalButton onClick={onClickEdit}>Продлить заказ</ModalButton>
              <ModalButton onClick={onClickDuplicate}>
                Дублировать на ночную смену
              </ModalButton>
            </StyledModal>
          )}
        </StyledModalWrapper>,
      ]);
    } else {
      return vehicleDetails.map((detail) => [
        detail.request?.number,
        detail.request?.organization?.name,
        detail.request?.project?.name,
        detail.vehicle?.govNumber,
        detail.request?.vehicleType?.name,
        detail.request?.serviceType?.name,
        detail.request?.characteristics?.name,
        detail.request?.unit.name,
        dateFormat(detail.request?.startDate, "dd.MM.yyyy HH:mm"),
        dateFormat(detail.request?.endDate, "dd.MM.yyyy HH:mm"),
        priceFormat(
          detail.nds?.isNds
            ? detail.price.toString()
            : detail.priceWithoutNds.toString()
        ),
        detail.request?.workShift?.type?.code === NIGHT_SHIFT_ID ? (
          <IconNight24 />
        ) : (
          <IconDay24 />
        ),
        <StyledModalWrapper key={detail.id}>
          <IconMore24 onClick={() => onClickMoreIcon(detail)} />
          {modalVisible && selectedItem && selectedItem.id === detail.id && (
            <StyledModal ref={modalRef}>
              <ModalButton onClick={onClickEdit}>Продлить заказ</ModalButton>
              <ModalButton onClick={onClickDuplicate}>
                Дублировать на ночную смену
              </ModalButton>
            </StyledModal>
          )}
        </StyledModalWrapper>,
      ]);
    }
  }, [
    dataSource,
    vehicleDetails,
    modalVisible,
    selectedItem,
    onClickEdit,
    onClickDuplicate,
  ]);

  return (
    <StyledMainContent>
      <StyledSidebar>
        <StyledHeaderText>Проекты</StyledHeaderText>
        <StyledSearchBar>
          <TextField
            value={searchText}
            onChange={(value) => onSearchChange(value)}
            placeholder="Организация, проект"
            disabled={false}
          />
          <StyledHeaderFilter visible={filterVisible} ref={ref}>
            <Button
              disabled={false}
              startIcon={IconFilter24}
              variant="outlined"
              onClick={onClickFilter}
            />
            <StyledVehiclesFilter visible={filterVisible}>
              <VehiclesProlongationFilter
                onSubmit={onSubmitFilter}
                defaultFilter={defaultFilter}
                getVehicleDetails={getVehicleDetails}
              />
            </StyledVehiclesFilter>
          </StyledHeaderFilter>
        </StyledSearchBar>
        {pending && (
          <StyledLoaderContainer absolute={false}>
            <Loader size="small" />
          </StyledLoaderContainer>
        )}
        <StyledVehicles overflowHidden={pendingVehicle}>
          {pendingVehicle && (
            <StyledLoaderContainer absolute={true}>
              <Loader size="small" />
            </StyledLoaderContainer>
          )}
          {!pending && (
            <>
              <ResetButton>
                <Checkbox
                  checked={selectedItems.size === data.length}
                  onChange={toggleSelectAllItems}
                  label={""}
                />
                <StyledResetButton
                  onClick={resetSelection}
                >{`Сбросить (${selectedItems.size})`}</StyledResetButton>
              </ResetButton>
              {data.map((item) => (
                <ListItem key={item.id}>
                  <Checkbox
                    checked={isChecked(item.id)}
                    onChange={() => toggleSelectItem(item.id)}
                    label={
                      <>
                        <div>{item.name}</div>
                        <div>{item.region.name}</div>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </>
          )}
        </StyledVehicles>
        <StyledPagination>
          <Pagination
            onChange={onChangePage}
            pageSize={20}
            initialPage={page}
            totalCount={totalCount}
            siblingCount={1}
          />
        </StyledPagination>
      </StyledSidebar>
      <StyledTableContainer>
        {vehicleDetails.length === 0 && selectedItems.size === 0 ? (
          <NoDataContainer>
            <IconVehicleTire56 />
            <StyledEmptyPanelText>
              Проекты для отображения еще не выбраны
            </StyledEmptyPanelText>
          </NoDataContainer>
        ) : (
          <Table
            labels={tableLabels}
            data={tableData}
            disableOverflow={true}
            stickyHeader={true}
            cellMaxWidth={500}
          />
        )}
      </StyledTableContainer>
      <ModalDuplication
        open={showDialog}
        title={`Дублирование заказа на ночную смену`}
        subtitle={`Укажите период смены и единицу измерения`}
        children={
          <>
            <div style={{ marginTop: 24 }}>
              <Select<WorkShift>
                label="Период смены"
                name="workShift"
                onChange={onChange}
                value={values.workShift}
                valueKey="id"
                labelKey="name"
                loadData={getWorkShifts}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Select<Unit>
                label="Единица измерения"
                name="unit"
                onChange={onChange}
                value={values.unit}
                valueKey="id"
                labelKey="name"
                loadData={loadUnits}
              />
            </div>
          </>
        }
        onConfirm={onConfirmDuplication}
        confirmLabel={"Дублировать"}
        onClose={onCloseDuplicationModal}
      />

      <Dialog
        open={showProlongationDialog}
        title={`Продлить заказ?`}
        subtitle={`Заказ №${selectedItem?.request?.number} будет продлен`}
        icon={IconWarningCircle24}
        onConfirm={onConfirmProlongation}
        confirmLabel={"Продлить"}
        onClose={onCloseProlongationDialog}
      />
      <StyledCalendarModal ref={modalDatePickerRef}>
        {dateTimePickerVisible && (
          <DateTimeCalendar
            value={values.endDate}
            name="endDate"
            onChange={onDateChange}
            minDate={minDate}
            maxDate={maxDate}
            onClose={onCloseDateTimeCalendar}
          />
        )}
      </StyledCalendarModal>
    </StyledMainContent>
  );
}

export default memo(PageVehicleProlongation);
