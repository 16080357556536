import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import {
  Button,
  DateTimePicker,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import {
  Company,
  Project,
  ServiceType,
  VehicleRequestStatusName,
  VehicleRequestStatusOption,
  VehicleType,
} from "@app/models";
import {
  getCompanies,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getServiceTypes,
  getVehicleTypes,
} from "@app/api";
import * as yup from "yup";
import { useUser } from "@app/providers";
import { companyLabelKeys, enumToOptions, isDispatcher } from "@app/helpers";

interface Props {
  onSubmit: (values: VehiclesFilterParams) => void;
  defaultFilter: VehiclesFilterParams;
  getVehicleDetails: (ids: string[], params: any) => Promise<void>;
}

export interface VehiclesFilterParams {
  numbers: string;
  status: SelectOption<VehicleRequestStatusOption> | null;
  organization: SelectOption<Company> | null;
  project: SelectOption<Project> | null;
  vehicleType: SelectOption<VehicleType> | null;
  govNumber: string;
  serviceType: SelectOption<ServiceType> | null;
  startDate: Date | null;
  endDate: Date | null;
}

const StyledFilter = styled.div<{ offset: number; maxHeight: number }>`
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  width: 360px;
  background-color: ${theme("color.white")};
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
  overflow: auto;
  max-height: ${(props) => `${props.maxHeight}px`};
`;

const StyledTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
`;

const schema = yup.object().shape({});

export default function VehiclesProlongationFilter(props: Props) {
  const { onSubmit, defaultFilter, getVehicleDetails } = props;
  const { user } = useUser();
  const { values, setValues, onChange } = useForm<VehiclesFilterParams>({
    values: defaultFilter,
    schema,
  });

  const ref = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(-15);
  const [maxHeight, setMaxHeight] = useState(885);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

  const onClickReset = useCallback(() => {
    setValues(defaultFilter);
    setSelectedItems(new Set());
  }, [setValues, defaultFilter]);

  const onClickSubmit = useCallback(() => {
    onSubmit(values);
  }, [values, onSubmit]);

  useEffect(() => {
    const { height = 0, top = 0 } = ref.current?.getBoundingClientRect() || {};
    if (height > window.outerHeight) {
      setMaxHeight(window.innerHeight);
    }
    if (height + top > window.outerHeight) {
      setOffset(window.outerHeight - window.innerHeight - top);
    }
  }, []);

  return (
    <StyledFilter ref={ref} offset={offset} maxHeight={maxHeight}>
      <Grid gap={24}>
        <StyledTitle>Фильтр</StyledTitle>
        <Grid gap={16}>
          <TextField
            label="Номер"
            placeholder="Введите номер"
            name="numbers"
            onChange={onChange}
            value={values.numbers}
          />
          <Select<Company>
            label="Организация"
            name="organization"
            onChange={onChange}
            value={values.organization}
            valueKey="id"
            labelKeys={companyLabelKeys}
            labelKeysSeparator={" / "}
            loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
          />
          <Select<Project>
            label="Проект"
            name="project"
            onChange={onChange}
            value={values.project}
            valueKey="id"
            labelKey="name"
            loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
          />
          <Select<VehicleType>
            label="Вид техники"
            name="vehicleType"
            onChange={onChange}
            value={values.vehicleType}
            valueKey="id"
            labelKey="name"
            loadData={getVehicleTypes}
          />
          <TextField
            label="Гос номер"
            placeholder="Введите гос номер"
            name="govNumber"
            onChange={onChange}
            value={values.govNumber}
          />
          <Select<ServiceType>
            label="Вид услуги"
            name="serviceType"
            onChange={onChange}
            value={values.serviceType}
            valueKey="id"
            labelKey="name"
            loadData={(params) =>
              getServiceTypes({ ...params, characteristicId: "" })
            }
          />
          <Select<any>
            label="Статус"
            name="status"
            onChange={onChange}
            value={values.status}
            valueKey="id"
            labelKey="name"
            options={enumToOptions(VehicleRequestStatusName)}
          />
          <DateTimePicker
            label="Дата начала"
            name="startDate"
            onChange={onChange}
            value={values.startDate}
            hideTime
          />
          <DateTimePicker
            label="Дата завершения"
            name="endDate"
            onChange={onChange}
            value={values.endDate}
            hideTime
          />
        </Grid>
        <Grid gap={16} columns={2}>
          <Button text="Сбросить" variant="outlined" onClick={onClickReset} />
          <Button text="Применить" onClick={onClickSubmit} />
        </Grid>
      </Grid>
    </StyledFilter>
  );
}
