export const content = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>

.organization-info ul {
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 0;
    }
    .organization-info ul li {
        /*display: inline-block;*/
        align-content: center;
        margin: 0 10px;
    }
    .info-text-create {
    justify-content: center;
    align-content: center;
    text-align: center;
        height: 20px;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;
        background-color: #D8D8D8;
        color: #636463; 
    }
    .info-text-moderate {
    width: 230px;
    justify-content: center;
    align-content: center;
        height: 22px;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;
        background-color: #FFB84E;
        color: #1C2246; 
    }
    
    .info-text-approved {
    justify-content: center;
    align-content: center;
        height: 20px;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;
        background-color: #5BCD71;
        color: #FFFFFF;
    }
    .info-text-rejected {
     justify-content: center;
    align-content: center;
        height: 20px;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;
        background-color: #DA616C;
        color: #FFFFFF;
    }
    .organization-info-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    }
    .organization-other-details {
     display: flex;
    flex-direction: row;
    margin-top: 24px;
      margin-bottom: 24px;
    }
    .organization-contacts {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    }
    .organization{ 
     display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    }
    .text{ 
    margin-left: 10px;
    }
</style>

</head>

<body lang=EN-US style='word-wrap:break-word'>

<div class="organization-info">
    <p>Для вашего удобства мы применили цветовую индикацию к каждому статусу:</p>
    
     <ul>
        <li class="organization-info-list">
        <div class="organization">
        <span class="info-text-create">Создан</span>
<span class="text">Ваша организация была создана, теперь вам нужно отправить ее на модерацию.
</span>
</div>

         </li>
        <li class="organization-info-list">
        <div class="organization">
        <span class="info-text-moderate">На рассмотрении</span>
<span class="text">Ваша организация в настоящее время находится на модерации. Обратите внимание, что проверка займет не более одного рабочего дня.
</span>
</div>

         </li>
        <li class="organization-info-list">
        <div class="organization">
       <span class="info-text-approved">Подтверждено</span>
<span class="text">Ваш запрос был одобрен, и ваша организация теперь активна.
</span>
</div>

         </li>
       <li class="organization-info-list">
        <div class="organization">
        <span class="info-text-rejected">Отклонено</span>
<span class="text">К сожалению, ваш запрос на создание организации был отклонен. Пожалуйста, свяжитесь с нами для получения дополнительной информации или исправьте предоставленные данные.
</span>
</div>
         </li>
    </ul>
    
    <p class="organization-info-text">В случае отсутствия обратной связи в течение одного рабочего дня просим обращаться по номеру телефона:</p>
 <li class="organization-contacts">
    <p>
       +7 708 625 5105- Куаныш
    </p>
    </li>
     <li class="organization-contacts">
    <p>
 +7 707 845 1898 - Улпан
    </p>
    </li>
     <li class="organization-contacts">
    <p>
       +7 771 046 0533 - Регина
    </p>
    </li>
   <li class="organization-other-details">
    <p>
        Ссылка на ватсап: <a href="http://wa.me/77710460533" target="_blank">http://wa.me/77710460533</a>
    </p>
    </li>
         <li class="organization-other-details">
    <p>
        Электронная почта: <a href="mailto:support@dispatchcds.com" target="_blank">support@dispatchcds.com</a>
    </p>
    </li>
</div>


</body>

</html>
`;
