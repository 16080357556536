import React, { memo, useCallback, useMemo } from "react";
import classNames from "classnames";
import { isSameDay } from "date-fns";

export interface ICalendarDay {
  day: Date;
  disabled?: boolean;
  selected?: boolean;
}

export interface Props extends Omit<ICalendarDay, "day"> {
  day: Date | string;
  onClick?: (day: ICalendarDay) => void;
}

function CalendarDay(props: Props) {
  const { onClick, disabled, selected, day } = props;

  const onClickDay = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!onClick) {
        return;
      }

      onClick({
        day: day as Date,
        selected,
        disabled,
      });
    },
    [onClick, day, selected, disabled]
  );

  const isCurrentDay = useMemo<boolean>(() => {
    if (typeof day === "string") {
      return false;
    }

    return isSameDay(day, new Date());
  }, [day]);

  return (
    <button
      className={classNames("b-calendar__day", {
        "b-calendar__day--selected": selected,
        "b-calendar__day--current": isCurrentDay,
        "b-calendar__day--disabled": disabled,
      })}
      onClick={onClickDay}
      disabled={disabled || !onClick}
    >
      <p className="b-calendar__text">
        {typeof day === "string" ? day : day.getDate().toString()}
      </p>
    </button>
  );
}

export default memo(CalendarDay);
