import { useCallback, useMemo } from "react";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import { ICalendarWeek } from "../../Calendar";

interface UseCalendarProps {
  date: Date | null;
  currentMonth: Date;
  setCurrentMonth: (date: Date) => void;
}

export interface IUseCalendarResult {
  currentMonth: Date;
  nextMonth: () => void;
  prevMonth: () => void;
  daysOfWeek: string[];
  data: ICalendarWeek[];
  setCurrentMonth: (date: Date) => void;
}

export function useCalendarCustom({
  date,
  currentMonth,
  setCurrentMonth,
}: UseCalendarProps): IUseCalendarResult {
  const currentDate = useMemo<Date>(() => new Date(), []);

  const daysOfWeek = useMemo<string[]>(() => {
    const days = [];
    const startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(format(addDays(startDate, i), "ddd"));
    }

    return days;
  }, [currentMonth]);

  const data = useMemo(() => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = "d";

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push({
          day: day,
          formattedDate: format(day, dateFormat),
          sameDay: isSameDay(day, currentDate),
          sameMonth: isSameMonth(day, monthStart),
        });
        day = addDays(day, 1);
      }

      rows.push(days);

      days = [];
    }
    return rows;
  }, [currentDate, currentMonth]);

  const nextMonth = useCallback(() => {
    setCurrentMonth(addMonths(currentMonth, 1));
  }, [currentMonth, setCurrentMonth]);

  const prevMonth = useCallback(() => {
    setCurrentMonth(subMonths(currentMonth, 1));
  }, [currentMonth, setCurrentMonth]);

  return {
    currentMonth,
    nextMonth,
    prevMonth,
    daysOfWeek,
    data,
    setCurrentMonth,
  };
}
