import { APIResponse, PageableParams, PageableResponse } from "./interfaces";
import {
  VAERequest,
  VAERequestOffer,
  VehicleRequest,
  VehicleRequestOffer,
} from "@app/models";
import instance from "./instance";

interface VehicleRequestCreateDTO {
  organizationId: string;
  projectId: string;
  vehicleTypeId: string;
  quantity: string;
  serviceTypeId: string;
  characteristicsId: string;
  workShiftId: string;
  volume: number;
  unitId: string;
  startDate: string;
  endDate: string;
  comments?: string;
}

interface VehicleAdditionalRequestDTO {
  customerId: string;
  projectId: string;
  partnerId: string;
  vehicleId: string;
  nomenclatureId: string;
  unitId: string;
  startDate: string;
  endDate: string;
  comments: string;
  volume: string;
}

interface CreateVehicleRequestOfferDTO {
  requestId: string;
  companyId: string;
  vehicleId: string;
  price: number;
  phone: string;
  email: string;
  comments: string;
  ndsId: string;
  files: File[];
}

interface UpdateVehicleRequestOfferDTO {
  id: string;
  requestId: string;
  companyId: string;
  vehicleId: string;
  price: number;
  phone: string;
  email: string;
  comments: string;
  ndsId: string;
  files: File[];
}

interface CreateVAERequestOfferDTO {
  requestId: string;
  companyId: string;
  vehicleId: string;
  nomenclatureId: string;
  unitId: string;
  currencyId: string;
  price: number;
  comments: string;
  ndsId: string;
  files: File[];
}

export interface VehicleRequestDuplicationDTO {
  id: string;
  unitId: string;
  workShiftId: string;
}

export interface VehicleRequestProlongationDTO {
  id: string;
  endDate: string;
}

export function getVehiclesRequests(
  params: PageableParams
): Promise<PageableResponse<VehicleRequest>> {
  return instance()
    .get("/vehicleRequest", {
      params,
    })
    .then((response) => response.data);
}

export function getVAERequests(
  params: PageableParams
): Promise<PageableResponse<VAERequest>> {
  return instance()
    .get("/additionalCost", {
      params,
    })
    .then((response) => response.data);
}

export function getVehicleRequest(
  id: string
): Promise<APIResponse<VehicleRequest>> {
  return instance()
    .get(`/vehicleRequest/${id}`)
    .then((response) => response.data);
}

export function getVAERequest(id: string): Promise<APIResponse<VAERequest>> {
  return instance()
    .get(`/additionalCost/${id}`)
    .then((response) => response.data);
}

export function createVehicleRequest(
  data: VehicleRequestCreateDTO
): Promise<APIResponse<VehicleRequest>> {
  return instance()
    .post("/vehicleRequest/create", data)
    .then((response) => response.data);
}

export function editVehicleRequest(
  data: Partial<VehicleRequest>
): Promise<APIResponse<VehicleRequest>> {
  return instance()
    .put("/vehicleRequest/edit", data)
    .then((response) => response.data);
}

export function onVehicleRequestProlongation(
  data: VehicleRequestProlongationDTO
) {
  return instance()
    .put("/vehicleRequest/Prolongation", data)
    .then((response) => response.data);
}

export function onVehicleRequestDuplication(
  data: VehicleRequestDuplicationDTO
) {
  return instance()
    .put("/vehicleRequest/Duplication", data)
    .then((response) => response.data);
}

export function editVAERequest(
  data: Partial<VAERequest>
): Promise<APIResponse<VAERequest>> {
  return instance()
    .put("/additionalCost/edit", data)
    .then((response) => response.data);
}

export function confirmVehicleRequest(data: {
  id: VehicleRequest["id"];
  sendToPartners: boolean;
}): Promise<APIResponse<VehicleRequest>> {
  return instance()
    .put("/vehicleRequest/confirm", data)
    .then((response) => response.data);
}

export function rejectVehicleRequest(
  data: Pick<VehicleRequest, "id">
): Promise<APIResponse<VehicleRequest>> {
  return instance()
    .put("/vehicleRequest/reject", data)
    .then((response) => response.data);
}

export function confirmVAERequest(
  data: Pick<VAERequest, "id">
): Promise<APIResponse<VAERequest>> {
  return instance()
    .put("/additionalCost/confirm", data)
    .then((response) => response.data);
}

export function rejectVAERequest(
  data: Pick<VAERequest, "id">
): Promise<APIResponse<VAERequest>> {
  return instance()
    .put("/additionalCost/reject", data)
    .then((response) => response.data);
}

export function createVAERequest(
  data: VehicleAdditionalRequestDTO
): Promise<APIResponse> {
  return instance()
    .post("/additionalCost/create", data)
    .then((response) => response.data);
}

export function getVehicleRequestOffers(
  params: PageableParams & {
    requestId?: string;
    projectIds?: string[][];
  }
): Promise<PageableResponse<VehicleRequestOffer>> {
  return instance()
    .get("/requestOffer", {
      params,
    })
    .then((response) => response.data);
}

export function getVAERequestOffers(
  params: PageableParams & {
    requestId: string;
  }
): Promise<PageableResponse<VAERequestOffer>> {
  return instance()
    .get("/additionalCostOffer", {
      params,
    })
    .then((response) => response.data);
}

export function confirmVAERequestOffer(id: string): Promise<APIResponse> {
  return instance()
    .post("/additionalCostOffer/selected", {
      id,
    })
    .then((response) => response.data);
}

export function confirmVehicleRequestOffer(id: string): Promise<APIResponse> {
  return instance()
    .post("/requestOffer/selected", {
      id,
    })
    .then((response) => response.data);
}

export function createVehicleRequestOffer(
  data: CreateVehicleRequestOfferDTO
): Promise<APIResponse<VehicleRequestOffer>> {
  const formData = new FormData();

  Object.keys(data).forEach((fieldKey) => {
    if (fieldKey === "files") {
      data[fieldKey].forEach((file) => {
        formData.append(`${fieldKey}[]`, file as Blob);
      });
    } else {
      formData.append(
        fieldKey,
        data[fieldKey as keyof CreateVehicleRequestOfferDTO] as string
      );
    }
  });
  return instance()
    .post("/requestOffer", formData)
    .then((response) => response.data);
}

export function updateVehicleRequestOffer(
  data: UpdateVehicleRequestOfferDTO
): Promise<APIResponse<VehicleRequestOffer>> {
  return instance()
    .put("/requestOffer", data)
    .then((response) => response.data);
}

export function deleteVehicleRequestOffer(
  id: VehicleRequestOffer["id"]
): Promise<APIResponse<VehicleRequestOffer>> {
  const params = { id };
  return instance()
    .delete("/requestOffer", { params })
    .then((response) => response.data);
}

export function getVehicleRequestOfferById(
  id: string
): Promise<APIResponse<VehicleRequestOffer>> {
  return instance()
    .get(`/requestOffer/${id}`)
    .then((response) => response.data);
}

export function createVAERequestOffer(
  data: CreateVAERequestOfferDTO
): Promise<APIResponse<VAERequestOffer>> {
  const formData = new FormData();

  Object.keys(data).forEach((fieldKey) => {
    if (fieldKey === "files") {
      data[fieldKey].forEach((file) => {
        formData.append(`${fieldKey}[]`, file as Blob);
      });
    } else {
      formData.append(
        fieldKey,
        data[fieldKey as keyof CreateVAERequestOfferDTO] as string
      );
    }
  });
  return instance()
    .post("/additionalCostOffer", formData)
    .then((response) => response.data);
}
